<template>
  <div class="inviteFriends">
    <mt-header :title="$t('inviteFriends')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="qrcode">
        <div>{{ $t('cashRedPacket') }}</div>
        <div>{{ $t('invitePrize') }}</div>
        <div id="qrCode" class="qrcode_code" ref="qrCodeDiv"></div>
      </div>
      <div class="item">
        <img class="bg" src="../../assets/inviteFriendsBg2.png">
        <div class="box">
          <div class="title">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#iconicon-test" />
            </svg>
            {{ $t('invitationAddress') }}
          </div>
          <div class="value link">
            <div class="url">{{ url }}</div>
            <mt-button @click="onCopy" class="copy" size="small" type="primary">{{ $t('copy') }}</mt-button>
          </div>
        </div>
      </div>
      <div class="item">
        <img class="bg" src="../../assets/inviteFriendsBg2.png">
        <div class="box">
          <div class="title">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#iconbuzhouliebiao" />
            </svg>
            {{ $t('inviteFriendsStep') }}
          </div>
          <div class="value step">
            <div class="step_item">
              {{ $t('inviteFriendsSteptips1') }}
            </div>
            <div class="step_item">
              {{ $t('inviteFriendsSteptips2') }}
            </div>
            <div class="step_item">
              {{ $t('inviteFriendsSteptips3') }}
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <img class="bg" src="../../assets/inviteFriendsBg2.png">
        <div class="box">
          <div class="title">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#iconguizezujian" />
            </svg>
            {{ $t('activityRules') }}
          </div>
          <div class="value">
            <div v-for="(item, index) in $t('activityRulesList')" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import QRCode from 'qrcodejs2'
import callPhone from '@/components/callPhone.vue'
import { getRecommendUrl } from '../../utils/api'
export default {
  name: 'InviteFriends',
  components: {
    callPhone
  },
  data() {
    return {
      url: ''
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    url() {
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.url,
        width: 100,
        height: 100,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
      });
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.getRecommendUrl()
      this.$indicator.close();
    },
    async getRecommendUrl() {
      await this.$axios({
        method: "get",
        url: getRecommendUrl,
        params: {
          idcard: this.user.idcard,
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.url = e.body
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    },
    onCopy() {
      let transfer = document.createElement('input');
      document.body.appendChild(transfer);
      transfer.readonly = 'readonly';
      transfer.value = this.url;
      transfer.focus();
      transfer.select();
      if (document.execCommand('copy')) {
          document.execCommand('copy');
      }
      transfer.blur();
      this.$toast(this.$t('copySuccess'));
      document.body.removeChild(transfer);
    }
  }
}
</script>

<style lang="scss" scoped>
.inviteFriends {
  background-color: #faf2e4;
  .qrcode {
    position: absolute;
    top: 64px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #947300;
    
    .qrcode_code {
      padding: 10px;
      margin-top: 5px;
      border-radius: 5px;
      background-color: white;
      box-shadow: -1px 2px 2px 1px #ccb5a5
    }
  }
  .content {
    overflow: hidden;
    background: url('../../assets/inviteFriendsBg.png') no-repeat;
    background-size: 100% auto;
    padding-top: 200px;
    .item {
      position: relative;
      border: 1px solid transparent;
      margin: 40px 20px;
      .bg {
        position: absolute;
        width: 100%;
      }
      .box {
        position: relative;
        margin: 4.5% 4% 0 4%;
        background-color: white;
        border-radius: 0px 0px 10px 10px;
        padding: 20px;
        box-shadow: 0px 2px 2px 1px #ccb5a5;
        border-top: 1px solid #8f9cde;
        font-size: 14px;
        .title {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 600;
          .icon {
            margin-right: 10px;
            font-size: 24px;
            vertical-align: sub;
          }
        }
      }
    }
    .link {
      display: flex;
      .url {
        flex: 1;
        font-size: 12px;
      }
      .copy {}
    }
    .step {
      display: flex;
      .step_item {
        position: relative;
        flex: 1;
        padding-top: 35px;
        text-align: center;
        @for $i from 1 through 3 {
          &:nth-child(#{$i})::before {
            content: '#{$i}';
          }
        }
        &::before {
          content: '';
          position: absolute;
          z-index: 1;
          height: 25px;
          width: 25px;
          line-height: 25px;
          background-color: #4c7fe7;
          color: white;
          border-radius: 25px;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 12.5px;
          height: 1px;
          background-color: #4c7fe7;
          width: 100%;
        }
        &:last-child::after {
          width: 50%;
        }
        &:first-child::after {
          width: 50%;
          left: auto;
          right: 0;
        }
      }
    }
  }
}
</style>